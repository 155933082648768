import React, { useState } from "react";
import { useDrag } from "@use-gesture/react";
import { DialContainer, CircleContainer, CircleFast, Dot } from "../styles";

import _ from "lodash";
import { styled } from "@mui/material";

export interface ISteppedDialProps {
  steps: { label: string | React.ReactElement<any>; value: any }[];
  label: string;
  selectedIndex: number;
  selectedStepChanged?: (value: any) => void;
}

interface ISteppedDialState {
  selectedStep: { label: string | React.ReactElement<any>; value: any };
}

const Tick = styled("div")`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  width: 10px;
  justify-content: space-between;
`;
const TickLine = styled("div")`
  flex-shrink: 0;
  height: 1px;
  width: 3px;
  margin: auto;
  margin-left: 2px;
  background: white;
`;
const TickContainer = styled("div")`
  position: absolute;
  height: 80px;
  width: 80px;
  left: 19px;
  top: -17px;
`;

const ThemedLabel = styled("span")`
  color: whitesmoke;
  font-family: "Source Code Pro", monospace;
  font-size: 8pt;
`;
const ANGLE_RANGE = 180;
const ANGLE_MAX = 90 + ANGLE_RANGE / 2;
const ANGLE_MIN = 90 - ANGLE_RANGE / 2;

export const SteppedDial = (props: ISteppedDialProps) => {
  const getTickAngle = (index: number, length: number): number => {
    const percentage = index / (length - 1);
    return percentage * ANGLE_RANGE + ANGLE_MIN;
  };

  const RADIUS = 20;

  const getPositionFromAngle = (angle: number): { x: number; y: number } => {
    let adjustedAngle = ANGLE_MAX - angle + ANGLE_MIN;
    if (adjustedAngle < 0) {
      adjustedAngle += 360;
    }

    const rads = (adjustedAngle * Math.PI) / 180;
    const x = RADIUS * 2 * Math.cos(rads) + RADIUS;
    const y = RADIUS * 2 * Math.sin(rads) + RADIUS;

    return { x, y };
  };

  const bind = useDrag(
    ({ offset: [, my] }) => {
      const percentVal = (-my + 75) / 150;

      const value = _.clamp(
        Math.round((props.steps.length - 1) * percentVal),
        0,
        props.steps.length - 1
      );

      props.selectedStepChanged &&
        props.selectedStepChanged(props.steps[value]);
    },
    {
      axis: "y",
      bounds: { top: -75, bottom: 75 },
    }
  );

  const { label, steps, selectedIndex } = props;

  const angle = getTickAngle(selectedIndex, steps.length);

  const style = {
    transform: `rotate(${angle}deg)`,
  };

  return (
    <DialContainer>
      <TickContainer>
        {props.steps.map((step, index) => {
          const tickAngle = getTickAngle(index, steps.length);
          const pos = getPositionFromAngle(tickAngle);

          const tickStyle = {
            bottom: `${pos.y}%`,
            left: `${pos.x}%`,
            transform: `rotate(${tickAngle}deg)`,
          };
          const tickLabelStyle = {
            transform: `rotate(${-tickAngle}deg)`,
          };
          return (
            <Tick style={tickStyle} key={step.value}>
              <TickLine />
              <ThemedLabel style={tickLabelStyle}>{step.label}</ThemedLabel>
            </Tick>
          );
        })}
      </TickContainer>
      <CircleContainer {...bind()}>
        <CircleFast style={style}>
          <Dot />
        </CircleFast>
      </CircleContainer>
      <ThemedLabel>{label}</ThemedLabel>
    </DialContainer>
  );
};
export default SteppedDial;
