import React, { useState } from "react";
import { useDrag } from "@use-gesture/react";

import {
  DialContainer,
  DialSVG,
  CircleContainer,
  Circle,
  Dot,
} from "../styles";
import { styled } from "@mui/material";

export interface IDialProps {
  valueChanged?: (value: number) => void;
  label: string;
  min: number;
  max: number;
  value: number;
  sensitivity?: number;
}

interface IDialState {
  value: number;
}

const ThemedLabel = styled("span")`
  color: whitesmoke;
  font-family: "Source Code Pro", monospace;
  font-size: 8pt;
  user-select: none;
`;

const ThemedPath = styled("path")(
  ({ theme }) => `
  stroke: ${theme.palette.primary.main};
`
);

export const Dial = (props: IDialProps) => {
  const valueToAngle = (value: number) => {
    const { max, min } = props;
    const range = max - min;
    const normalizedValue = value - min;
    return 270 * (normalizedValue / range) - 45;
  };

  const { label, max, min } = props;

  const { value } = props;

  let angle = valueToAngle(props.value);
  const style = {
    transform: `rotate(${angle}deg)`,
  };

  const range = max - min;
  const percentageValue = (value - min) / range;

  const bind = useDrag(
    ({ offset: [, my] }) => {
      const percentVal = -my + 50 * (props.sensitivity || 1);

      const range = max - min;

      const value =
        min + range * ((percentVal / 100) * (props.sensitivity || 1));

      props.valueChanged && props.valueChanged(value);
    },
    {
      axis: "y",
      bounds: {
        top: -50 * (props.sensitivity || 1),
        bottom: 50 * (props.sensitivity || 1),
      },
    }
  );

  return (
    <DialContainer>
      <DialSVG viewBox="0 0 100 100">
        <path d="M20,76 A 40 40 0 1 1 80 76" fill="none" stroke="#55595C" />
        <ThemedPath
          d="M20,76 A 40 40 0 1 1 80 76"
          fill="none"
          style={{
            strokeDashoffset: 184 - 184 * percentageValue,
          }}
        ></ThemedPath>
      </DialSVG>
      <CircleContainer {...bind()}>
        <Circle style={style}>
          <Dot />
        </Circle>
      </CircleContainer>
      <ThemedLabel>{label}</ThemedLabel>
    </DialContainer>
  );
};

export default Dial;
