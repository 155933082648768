import React, { Component } from "react";
import noteTable from "../../synth/noteTable";
import Key from "../key/key";
import styled from "styled-components";
import { Synth } from "../../synth/synth";
import { getNoteString, INote } from "../../synth/note";

const KeysWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

const KeysContainer = styled.ul`
  padding: 0;
  height: 300px;
  padding-right: 10px;
  padding-left: 10px;
  list-style: none;
  box-sizing: border-box;
`;

interface IKeyboardProps {
  synth?: Synth;
}

const getScreenKeys = () => {
  const notes: INote[] = [];
  let octave = 4;
  let note = "C";
  const screenKeysCount = 12 * 3 + 1;

  for (let i = 0; i < screenKeysCount; i++) {
    notes.push(noteTable[`${note}${octave}`]);
    if (note === "C") {
      note = "C#";
      continue;
    }
    if (note === "C#") {
      note = "D";
      continue;
    }
    if (note === "D") {
      note = "D#";
      continue;
    }
    if (note === "D#") {
      note = "E";
      continue;
    }
    if (note === "E") {
      note = "F";
      continue;
    }
    if (note === "F") {
      note = "F#";
      continue;
    }
    if (note === "F#") {
      note = "G";
      continue;
    }
    if (note === "G") {
      note = "G#";
      continue;
    }
    if (note === "G#") {
      note = "A";
      continue;
    }
    if (note === "A") {
      note = "A#";
      continue;
    }
    if (note === "A#") {
      note = "B";
      continue;
    }
    if (note === "B") {
      note = "C";
      octave++;
      continue;
    }
  }

  return notes;
};

const screenKeys = getScreenKeys();

class Keyboard extends Component<IKeyboardProps> {
  public render = () => {
    return (
      <KeysWrapper>
        <KeysContainer>
          {screenKeys.map((note, index) => {
            return (
              <Key
                synth={this.props.synth}
                key={`${note.octave}-${getNoteString(note)}-${index}`}
                note={note}
              />
            );
          })}
        </KeysContainer>
      </KeysWrapper>
    );
  };
}

export default Keyboard;
