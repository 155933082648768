import noteTable from "./noteTable";
import { INote } from "./note";

export const mapKeyToNote = (key: string): INote | null => {
  switch (key) {
    case "z":
      return noteTable["C4"];
    case "s":
      return noteTable["C#4"];
    case "x":
      return noteTable["D4"];
    case "d":
      return noteTable["D#4"];
    case "c":
      return noteTable["E4"];
    case "v":
      return noteTable["F4"];
    case "g":
      return noteTable["F#4"];
    case "b":
      return noteTable["G4"];
    case "h":
      return noteTable["G#4"];
    case "n":
      return noteTable["A4"];
    case "j":
      return noteTable["A#4"];
    case "m":
      return noteTable["B4"];
    case ",":
    case "q":
      return noteTable["C5"];
    case "l":
    case "2":
      return noteTable["C#5"];
    case "w":
    case ".":
      return noteTable["D5"];
    case "3":
    case ";":
      return noteTable["D#5"];
    case "e":
    case "/":
      return noteTable["E5"];
    case "r":
      return noteTable["F5"];
    case "5":
      return noteTable["F#5"];
    case "t":
      return noteTable["G5"];
    case "6":
      return noteTable["G#5"];
    case "y":
      return noteTable["A5"];
    case "7":
      return noteTable["A#5"];
    case "u":
      return noteTable["B5"];
    case "i":
      return noteTable["C6"];
    case "9":
      return noteTable["C#6"];
    case "o":
      return noteTable["D6"];
    case "0":
      return noteTable["D#6"];
    case "p":
      return noteTable["E6"];
    case "[":
      return noteTable["F6"];
    case "=":
      return noteTable["F#6"];
    case "]":
      return noteTable["G6"];
    default:
      return null;
  }
};
const getMap = () => {
  const map: Record<string, INote> = {};
  for (var i = 32; i < 127; ++i) {
    const key = String.fromCharCode(i);
    const note = mapKeyToNote(key);
    if (note) {
      map[key] = note;
    }
  }

  return map;
};

export default getMap();
