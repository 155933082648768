export interface INote {
  octave: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  name: "C" | "D" | "E" | "F" | "G" | "A" | "B";
  accidental: "sharp" | "flat" | "natural";
  frequency: number;
}

export const getNoteString = ({
  octave,
  name,
  accidental,
}: Pick<INote, "octave" | "name" | "accidental">) => {
  if (accidental === "natural") {
    return `${name}${octave}`;
  }

  return `${name}${accidental === "flat" ? "b" : "#"}${octave}`;
};
