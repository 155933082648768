import IEnvelope from "./envelope";
import { getNoteString, INote } from "./note";
import noteTable from "./noteTable";

export interface OscillatorSettings
  extends Pick<OscillatorOptions, "type" | "detune"> {
  octave: number;
  level: number;
}

class Note {
  private synth: Synth;
  private context: AudioContext;
  private filterNode: BiquadFilterNode;
  private playing = false;
  private note: INote;
  constructor(
    note: INote,
    context: AudioContext,
    synth: Synth,
    filterNode: BiquadFilterNode
  ) {
    this.synth = synth;
    this.context = context;
    this.note = note;
    this.filterNode = filterNode;
  }

  play(velocity: number) {
    if (this.playing) {
      return;
    }

    this.playing = true;
    const now = this.context.currentTime;
    const oscillators: OscillatorNode[] = [];
    const envelopeGain = this.context.createGain();
    envelopeGain.gain.setValueAtTime(0, this.context.currentTime);
    envelopeGain.connect(this.filterNode);
    for (const setting of this.synth.settings.oscillators) {
      const oscillator = this.context.createOscillator();
      this.synth.currentOscillators.push(oscillator);
      const levelGain = this.context.createGain();
      levelGain.gain.setValueAtTime(setting.level, this.context.currentTime);
      const now = this.context.currentTime;
      const freq = this.note.frequency * 2 ** setting.octave;

      oscillator.frequency.setValueAtTime(freq, now);
      oscillator.type = setting.type!;
      oscillator.detune.setValueAtTime(
        setting.detune || 0 + this.synth.detune,
        now
      );
      oscillator.connect(levelGain);
      levelGain.connect(envelopeGain);
      oscillators.push(oscillator);

      oscillator.start();

      oscillator.onended = () => {
        envelopeGain.disconnect();
        oscillator.disconnect();
        levelGain.disconnect();
      };
    }

    const targetGain = 1 * (velocity / 50);
    console.log(targetGain);
    envelopeGain.gain.cancelScheduledValues(now);
    envelopeGain.gain.setValueAtTime(0, now);
    envelopeGain.gain.setTargetAtTime(
      targetGain,
      now,
      this.synth.settings.envelope.attack
    );
    envelopeGain.gain.setTargetAtTime(
      this.synth.settings.envelope.sustain * (velocity / 50),
      now + this.synth.settings.envelope.attack,
      this.synth.settings.envelope.decay
    );

    return {
      stopNow: () => {
        const releaseNow = this.context.currentTime;
        envelopeGain.gain.cancelScheduledValues(releaseNow);

        envelopeGain.gain.setValueAtTime(0, releaseNow);
        for (const oscillator of oscillators) {
          oscillator.stop(releaseNow);
          this.synth.currentOscillators.splice(
            this.synth.currentOscillators.indexOf(oscillator),
            1
          );
        }
      },
      release: () => {
        const releaseNow = this.context.currentTime;
        envelopeGain.gain.cancelScheduledValues(releaseNow);
        envelopeGain.gain.setTargetAtTime(
          0,
          releaseNow,
          this.synth.settings.envelope.release
        );

        for (const oscillator of oscillators) {
          oscillator.stop(
            releaseNow + this.synth.settings.envelope.release + 1
          );
          this.synth.currentOscillators.splice(
            this.synth.currentOscillators.indexOf(oscillator),
            1
          );
        }

        this.playing = false;
      },
    };
  }
}

interface ILfoSettings {
  gain: number;
  frequency: number;
}

export interface ISynthSettings {
  envelope: IEnvelope;
  oscillators: OscillatorSettings[];
  lfo: ILfoSettings;
  volume: number;
}

export class Synth {
  private context?: AudioContext;
  private masterGainNode?: GainNode;
  private filterNode?: BiquadFilterNode;
  private lfoGain?: GainNode;
  private lfoWave?: OscillatorNode;
  public notes?: Record<string, Note> = {};
  public isPoweredOn: boolean = false;
  public currentOscillators: OscillatorNode[] = [];
  public detune: number = 0;
  constructor(public settings: ISynthSettings) {}

  public togglePower = async () => {
    if (this.isPoweredOn) {
      this.context = undefined;
      this.masterGainNode = undefined;
      this.filterNode = undefined;
      this.lfoGain = undefined;
      this.lfoWave = undefined;
      this.notes = undefined;
    } else {
      this.context = new AudioContext();
      this.context.createBuffer(2, 22050, 44100);
      this.masterGainNode = this.context.createGain();
      this.masterGainNode.connect(this.context.destination);
      this.masterGainNode.gain.value = this.settings.volume;
      this.filterNode = this.context.createBiquadFilter();

      this.filterNode.connect(this.masterGainNode);
      this.filterNode.type = "highpass";
      this.filterNode.gain.value = 25;

      this.lfoWave = this.context.createOscillator();
      this.lfoGain = this.context.createGain();
      this.lfoWave.type = "sine";
      this.lfoWave.frequency.value = this.settings.volume;
      this.lfoWave.start();
      this.lfoGain.gain.value = this.settings.lfo.gain;

      //connect the dots
      this.lfoWave.connect(this.lfoGain);
      this.lfoGain.connect(this.filterNode.frequency);

      this.notes = {};
      for (const key in noteTable) {
        const note = noteTable[key];
        this.notes[key] = new Note(note, this.context, this, this.filterNode);
      }

      // await this.playPowerOnSound();
    }
    this.isPoweredOn = !this.isPoweredOn;
  };

  public updateSettings = (settings: ISynthSettings) => {
    if (settings.volume !== undefined) {
      if (this.masterGainNode) {
        this.masterGainNode.gain.value = settings.volume;
      } else {
        console.log("no node");
      }
    }

    if (settings.lfo !== undefined) {
      this.lfoWave!.frequency.setValueAtTime(
        settings.lfo.frequency,
        this.context!.currentTime
      );
      this.lfoGain!.gain.setValueAtTime(
        settings.lfo.gain,
        this.context!.currentTime
      );
    }

    this.settings = { ...this.settings, ...settings };
  };

  public playNote = ({ octave, name, accidental }: INote, velocity = 50) => {
    if (this.isPoweredOn) {
      return this.notes![getNoteString({ octave, name, accidental })].play(
        velocity
      );
    }
  };

  public detuneNotes = (detune: number) => {
    this.detune = detune;
    console.log(`Bending notes: ${detune}`);
    if (this.context) {
      const now = this.context.currentTime;
      for (const oscillator of this.currentOscillators) {
        oscillator.detune.setValueAtTime(detune, now);
      }
    }
  };

  private playPowerOnSound = (): Promise<void[]> => {
    if (!this.context || !this.filterNode) {
      return Promise.resolve([]);
    }
    const now = this.context.currentTime;
    // const envelopeGain = this.context.createGain();
    // envelopeGain.gain.setValueAtTime(0, this.context.currentTime);
    // envelopeGain.connect(this.filterNode);
    const notePromises: Promise<void>[] = [];
    const baseLower1Freq = noteTable["C4"].frequency;
    const baseLower2Freq = noteTable["E4"].frequency;
    const baseLower3Freq = noteTable["G4"].frequency;
    const baseUpper1Freq = noteTable["C5"].frequency;
    const baseUpper2Freq = noteTable["E5"].frequency;
    const baseUpper3Freq = noteTable["G6"].frequency;
    for (const setting of this.settings.oscillators) {
      notePromises.push(
        new Promise<void>((resolve) => {
          if (!this.context || !this.filterNode) {
            return resolve();
          }
          const oscillator1 = this.context.createOscillator();
          const oscillator2 = this.context.createOscillator();
          const oscillator3 = this.context.createOscillator();
          const levelGain = this.context.createGain();
          levelGain.gain.setValueAtTime(setting.level, now);
          const lower1Freq = baseLower1Freq * 2 ** setting.octave;
          const lower2Freq = baseLower2Freq * 2 ** setting.octave;
          const lower3Freq = baseLower3Freq * 2 ** setting.octave;

          const upper1Freq = baseUpper1Freq * 2 ** setting.octave;
          const upper2Freq = baseUpper2Freq * 2 ** setting.octave;
          const upper3Freq = baseUpper3Freq * 2 ** setting.octave;

          oscillator1.frequency.setValueAtTime(lower1Freq, now);
          oscillator1.type = setting.type!;
          oscillator1.detune.setValueAtTime(setting.detune!, now);
          oscillator1.connect(levelGain);
          oscillator2.frequency.setValueAtTime(lower2Freq, now);
          oscillator2.type = setting.type!;
          oscillator2.detune.setValueAtTime(setting.detune!, now);
          oscillator2.connect(levelGain);
          oscillator3.frequency.setValueAtTime(lower3Freq, now);
          oscillator3.type = setting.type!;
          oscillator3.detune.setValueAtTime(setting.detune!, now);
          oscillator3.connect(levelGain);

          levelGain.connect(this.filterNode);
          // levelGain.connect(envelopeGain);

          oscillator1.start(now);
          oscillator1.frequency.exponentialRampToValueAtTime(
            upper1Freq,
            now + 1.5
          );
          oscillator1.stop(now + 4);
          oscillator2.start(now + 0.5);
          oscillator2.frequency.exponentialRampToValueAtTime(
            upper2Freq,
            now + 2
          );
          oscillator2.stop(now + 4);
          oscillator3.start(now + 1.5);
          oscillator3.frequency.exponentialRampToValueAtTime(
            upper3Freq,
            now + 3
          );
          oscillator3.stop(now + 4);

          oscillator1.onended = () => {
            // envelopeGain.disconnect();
            oscillator1.disconnect();
            levelGain.disconnect();

            resolve();
          };
        })
      );
    }

    return Promise.all(notePromises);
  };
}
