import React, { Component } from "react";
import { INote } from "../../synth/note";
import styled from "styled-components";
import { Synth } from "../../synth/synth";
import keymap from "../../synth/keymap";
import _ from "lodash";

const KeyLabel = styled.span`
  user-select: none;
  margin: auto;
  bottom: 0;
  font-weight: 500;
  text-align: center;
  width: 100%;
  font-family: "Source Code Pro", monospace;
  font-size: 8pt;
`;

const BlackKey = styled.li`
  background: black;
  text-align: center;
  width: 30px;
  height: 180px;
  float: left;
  margin-left: -30px;
  transform: translateX(50%);
  border-radius: 0 0 5px 5px;
  box-shadow: inset 0px -2px 1px 2px rgba(255, 255, 255, 0.3);

  &.active {
    height: 175px;
    background-color: #333333;
  }

  ${KeyLabel} {
    color: white;
  }
`;

const WhiteKey = styled.li`
  background: white;
  width: 60px;
  text-align: center;
  height: 300px;
  border: 1px solid black;
  box-sizing: border-box;
  float: left;
  border-radius: 0 0 10px 10px;
  box-shadow: inset 0px -2px 10px 1px rgba(0, 0, 0, 0.13);
  &.active {
    height: 295px;
    background-color: #eeeeee;
  }

  ${KeyLabel} {
    color: black;
  }
`;

export interface IKeyProps {
  note: INote;
  synth?: Synth;
}

interface IKeyState {
  playing: boolean;
}

class Key extends Component<IKeyProps, IKeyState> {
  private release?: () => void;
  private stopNow?: () => void;
  public state = { playing: false };
  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  public render() {
    const {
      note: { accidental, name, octave },
    } = this.props;
    const { playing } = this.state;
    if (accidental !== "natural") {
      return (
        <BlackKey
          className={playing ? "active" : ""}
          onPointerMove={this.handleMouseDown}
          onPointerDown={this.handleMouseDown}
          onPointerLeave={this.handleMouseUp}
          onPointerUp={this.handleMouseUp}
        >
          <KeyLabel>
            {name}
            {accidental === "flat" ? "b" : "#"}
            {octave}
          </KeyLabel>
        </BlackKey>
      );
    }
    return (
      <WhiteKey
        className={playing ? "active" : ""}
        onPointerMove={this.handleMouseDown}
        onPointerDown={this.handleMouseDown}
        onPointerLeave={this.handleMouseUp}
        onPointerUp={this.handleMouseUp}
      >
        <KeyLabel>
          {name}
          {octave}
        </KeyLabel>
      </WhiteKey>
    );
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    const { note } = this.props;
    const mappedNote = keymap[event.key];
    if (mappedNote && _.isEqual(mappedNote, note)) {
      this.playNote();
    }
  };

  private handleKeyUp = (event: KeyboardEvent) => {
    const { note } = this.props;
    const mappedNote = keymap[event.key];
    if (mappedNote && _.isEqual(mappedNote, note)) {
      this.releaseNote();
    }
  };

  private handleTouchMove = (ev: React.TouchEvent<HTMLElement>) => {
    this.playNote();
  };

  private handleMouseDown = (ev: React.PointerEvent<HTMLLIElement>) => {
    if (!!ev.buttons && ev.buttons !== -1) {
      this.playNote();
    }
  };

  private handleMouseUp = (ev: React.PointerEvent<HTMLLIElement>) => {
    this.releaseNote();
  };
  private playNote = () => {
    const { playing } = this.state;
    if (playing) {
      return;
    }
    const { note } = this.props;

    if (this.stopNow) {
      this.stopNow();
    }

    this.setState({ playing: true });
    const playedNote = this.props.synth?.playNote(note);
    if (playedNote) {
      this.release = playedNote.release;
      this.stopNow = playedNote.stopNow;
    }
  };

  private releaseNote = () => {
    if (this.release) {
      this.release();
      this.release = undefined;
    }
    this.setState({ playing: false });
  };
}

export default Key;
