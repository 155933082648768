import { styled } from "@mui/material";
import React from "react";

export interface IContainerProps {
  label?: string;
}

const ContainerRoot = styled("div")(
  ({ theme }) => `
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid ${theme.palette.grey[600]};
  padding: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  border-radius: 8px;
`
);
const ContainerLabel = styled("span")(
  ({ theme }) => `
  position: absolute;
  top: -6px;
  color: whitesmoke;
  background: ${theme.palette.grey[900]};
  font-family: "Source Code Pro", monospace;
  font-size: 8pt;
`
);

const Container: React.FC<IContainerProps> = ({ label, children }) => (
  <ContainerRoot>
    {label && <ContainerLabel>{label}</ContainerLabel>}
    {children}
  </ContainerRoot>
);

export default Container;
