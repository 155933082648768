import { getNoteString, INote } from "./note";
import noteTable from "./noteTable";

const getMidiMap = () => {
  const map: Record<number, INote> = {};
  let octave = 2;
  let note = "C";
  for (let midiNote = 36; midiNote <= 96; midiNote++) {
    if (noteTable[`${note}${octave}`]) {
      map[midiNote] = noteTable[`${note}${octave}`];
    }

    if (note === "C") {
      note = "C#";
      continue;
    }
    if (note === "C#") {
      note = "D";
      continue;
    }
    if (note === "D") {
      note = "D#";
      continue;
    }
    if (note === "D#") {
      note = "E";
      continue;
    }
    if (note === "E") {
      note = "F";
      continue;
    }
    if (note === "F") {
      note = "F#";
      continue;
    }
    if (note === "F#") {
      note = "G";
      continue;
    }
    if (note === "G") {
      note = "G#";
      continue;
    }
    if (note === "G#") {
      note = "A";
      continue;
    }
    if (note === "A") {
      note = "A#";
      continue;
    }
    if (note === "A#") {
      note = "B";
      continue;
    }
    if (note === "B") {
      note = "C";
      octave++;
      continue;
    }
  }

  return map;
};

export default getMidiMap();
