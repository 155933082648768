import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import Synth from "./components/synth/synth";
import { green, purple } from "@mui/material/colors";

const darkTheme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      main: purple[700],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Synth />
    </ThemeProvider>
  );
}

export default App;
