import styled from "styled-components";

export const DialSVG = styled.svg`
  pointer-events: none;
  stroke-width: 8;
  position: absolute;
  stroke-dasharray: 184 184;
  & path {
    transition: 0.3s ease-out;
  }
`;

export const DialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin-top: 4px;
  user-select: none;
  position: relative;
`;

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  box-shadow: 5px 5px 35px 3px rgba(0, 0, 0, 0.39);
  touch-action: none;
`;

export const Circle = styled.div`
  margin-top: -8px;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 40px;
  border: 2px solid #6f6f6f;
  transition: transform 0.3s ease-out;
`;

export const CircleFast = styled.div`
  margin-top: -8px;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 40px;
  border: 2px solid #6f6f6f;
  transition: transform 0.1s ease-in;
`;

export const Dot = styled.div`
  position: absolute;
  height: 2px;
  width: 10px;
  top: 18px;
  left: 6px;
  margin-top: 0px;
  background: white;
  border-radius: 2px;
`;
export const styles = {
  label: {
    color: "#fff",
    userSelect: "none",
    marginTop: 10,
    fontSize: "10px",
  },
};
