import { INote } from "./note";

const createNoteTable = (): Record<string, INote> => {
  let noteFreq: Record<string, INote> = {};

  noteFreq["A0"] = {
    name: "A",
    octave: 0,
    accidental: "natural",
    frequency: 27.5,
  };
  noteFreq["A#0"] = {
    name: "A",
    octave: 0,
    accidental: "sharp",
    frequency: 29.135235094880619,
  };
  noteFreq["B0"] = {
    name: "B",
    octave: 0,
    accidental: "natural",
    frequency: 30.867706328507756,
  };

  noteFreq["C1"] = {
    name: "C",
    octave: 1,
    accidental: "natural",
    frequency: 32.703195662574829,
  };
  noteFreq["C#1"] = {
    name: "C",
    octave: 1,
    accidental: "sharp",
    frequency: 34.647828872109012,
  };
  noteFreq["D1"] = {
    name: "D",
    octave: 1,
    accidental: "natural",
    frequency: 36.708095989675945,
  };
  noteFreq["D#1"] = {
    name: "D",
    octave: 1,
    accidental: "sharp",
    frequency: 38.890872965260113,
  };
  noteFreq["E1"] = {
    name: "E",
    octave: 1,
    accidental: "natural",
    frequency: 41.203444614108741,
  };
  noteFreq["F1"] = {
    name: "F",
    octave: 1,
    accidental: "natural",
    frequency: 43.653528929125485,
  };
  noteFreq["F#1"] = {
    name: "F",
    octave: 1,
    accidental: "sharp",
    frequency: 46.249302838954299,
  };
  noteFreq["G1"] = {
    name: "G",
    octave: 1,
    accidental: "natural",
    frequency: 48.999429497718661,
  };
  noteFreq["G#1"] = {
    name: "G",
    octave: 1,
    accidental: "sharp",
    frequency: 51.913087197493142,
  };
  noteFreq["A1"] = {
    name: "A",
    octave: 1,
    accidental: "natural",
    frequency: 55.0,
  };
  noteFreq["A#1"] = {
    name: "A",
    octave: 1,
    accidental: "sharp",
    frequency: 58.270470189761239,
  };
  noteFreq["B1"] = {
    name: "B",
    octave: 1,
    accidental: "natural",
    frequency: 61.735412657015513,
  };

  noteFreq["C2"] = {
    name: "C",
    octave: 2,
    accidental: "natural",
    frequency: 65.406391325149658,
  };
  noteFreq["C#2"] = {
    name: "C",
    octave: 2,
    accidental: "sharp",
    frequency: 69.295657744218024,
  };
  noteFreq["D2"] = {
    name: "D",
    octave: 2,
    accidental: "natural",
    frequency: 73.41619197935189,
  };
  noteFreq["D#2"] = {
    name: "D",
    octave: 2,
    accidental: "sharp",
    frequency: 77.781745930520227,
  };
  noteFreq["E2"] = {
    name: "E",
    octave: 2,
    accidental: "natural",
    frequency: 82.406889228217482,
  };
  noteFreq["F2"] = {
    name: "F",
    octave: 2,
    accidental: "natural",
    frequency: 87.307057858250971,
  };
  noteFreq["F#2"] = {
    name: "F",
    octave: 2,
    accidental: "sharp",
    frequency: 92.498605677908599,
  };
  noteFreq["G2"] = {
    name: "G",
    octave: 2,
    accidental: "natural",
    frequency: 97.998858995437323,
  };
  noteFreq["G#2"] = {
    name: "G",
    octave: 2,
    accidental: "sharp",
    frequency: 103.826174394986284,
  };
  noteFreq["A2"] = {
    name: "A",
    octave: 2,
    accidental: "natural",
    frequency: 110.0,
  };
  noteFreq["A#2"] = {
    name: "A",
    octave: 2,
    accidental: "sharp",
    frequency: 116.540940379522479,
  };
  noteFreq["B2"] = {
    name: "B",
    octave: 2,
    accidental: "natural",
    frequency: 123.470825314031027,
  };

  noteFreq["C3"] = {
    name: "C",
    octave: 3,
    accidental: "natural",
    frequency: 130.812782650299317,
  };
  noteFreq["C#3"] = {
    name: "C",
    octave: 3,
    accidental: "sharp",
    frequency: 138.591315488436048,
  };
  noteFreq["D3"] = {
    name: "D",
    octave: 3,
    accidental: "natural",
    frequency: 146.83238395870378,
  };
  noteFreq["D#3"] = {
    name: "D",
    octave: 3,
    accidental: "sharp",
    frequency: 155.563491861040455,
  };
  noteFreq["E3"] = {
    name: "E",
    octave: 3,
    accidental: "natural",
    frequency: 164.813778456434964,
  };
  noteFreq["F3"] = {
    name: "F",
    octave: 3,
    accidental: "natural",
    frequency: 174.614115716501942,
  };
  noteFreq["F#3"] = {
    name: "F",
    octave: 3,
    accidental: "sharp",
    frequency: 184.997211355817199,
  };
  noteFreq["G3"] = {
    name: "G",
    octave: 3,
    accidental: "natural",
    frequency: 195.997717990874647,
  };
  noteFreq["G#3"] = {
    name: "G",
    octave: 3,
    accidental: "sharp",
    frequency: 207.652348789972569,
  };
  noteFreq["A3"] = {
    name: "A",
    octave: 3,
    accidental: "natural",
    frequency: 220.0,
  };
  noteFreq["A#3"] = {
    name: "A",
    octave: 3,
    accidental: "sharp",
    frequency: 233.081880759044958,
  };
  noteFreq["B3"] = {
    name: "B",
    octave: 3,
    accidental: "natural",
    frequency: 246.941650628062055,
  };

  noteFreq["C4"] = {
    name: "C",
    octave: 4,
    accidental: "natural",
    frequency: 261.625565300598634,
  };
  noteFreq["C#4"] = {
    name: "C",
    octave: 4,
    accidental: "sharp",
    frequency: 277.182630976872096,
  };
  noteFreq["D4"] = {
    name: "D",
    octave: 4,
    accidental: "natural",
    frequency: 293.66476791740756,
  };
  noteFreq["D#4"] = {
    name: "D",
    octave: 4,
    accidental: "sharp",
    frequency: 311.12698372208091,
  };
  noteFreq["E4"] = {
    name: "E",
    octave: 4,
    accidental: "natural",
    frequency: 329.627556912869929,
  };
  noteFreq["F4"] = {
    name: "F",
    octave: 4,
    accidental: "natural",
    frequency: 349.228231433003884,
  };
  noteFreq["F#4"] = {
    name: "F",
    octave: 4,
    accidental: "sharp",
    frequency: 369.994422711634398,
  };
  noteFreq["G4"] = {
    name: "G",
    octave: 4,
    accidental: "natural",
    frequency: 391.995435981749294,
  };
  noteFreq["G#4"] = {
    name: "G",
    octave: 4,
    accidental: "sharp",
    frequency: 415.304697579945138,
  };
  noteFreq["A4"] = {
    name: "A",
    octave: 4,
    accidental: "natural",
    frequency: 440.0,
  };
  noteFreq["A#4"] = {
    name: "A",
    octave: 4,
    accidental: "sharp",
    frequency: 466.163761518089916,
  };
  noteFreq["B4"] = {
    name: "B",
    octave: 4,
    accidental: "natural",
    frequency: 493.883301256124111,
  };

  noteFreq["C5"] = {
    name: "C",
    octave: 5,
    accidental: "natural",
    frequency: 523.251130601197269,
  };
  noteFreq["C#5"] = {
    name: "C",
    octave: 5,
    accidental: "sharp",
    frequency: 554.365261953744192,
  };
  noteFreq["D5"] = {
    name: "D",
    octave: 5,
    accidental: "natural",
    frequency: 587.32953583481512,
  };
  noteFreq["D#5"] = {
    name: "D",
    octave: 5,
    accidental: "sharp",
    frequency: 622.253967444161821,
  };
  noteFreq["E5"] = {
    name: "E",
    octave: 5,
    accidental: "natural",
    frequency: 659.255113825739859,
  };
  noteFreq["F5"] = {
    name: "F",
    octave: 5,
    accidental: "natural",
    frequency: 698.456462866007768,
  };
  noteFreq["F#5"] = {
    name: "F",
    octave: 5,
    accidental: "sharp",
    frequency: 739.988845423268797,
  };
  noteFreq["G5"] = {
    name: "G",
    octave: 5,
    accidental: "natural",
    frequency: 783.990871963498588,
  };
  noteFreq["G#5"] = {
    name: "G",
    octave: 5,
    accidental: "sharp",
    frequency: 830.609395159890277,
  };
  noteFreq["A5"] = {
    name: "A",
    octave: 5,
    accidental: "natural",
    frequency: 880.0,
  };
  noteFreq["A#5"] = {
    name: "A",
    octave: 5,
    accidental: "sharp",
    frequency: 932.327523036179832,
  };
  noteFreq["B5"] = {
    name: "B",
    octave: 5,
    accidental: "natural",
    frequency: 987.766602512248223,
  };

  noteFreq["C6"] = {
    name: "C",
    octave: 6,
    accidental: "natural",
    frequency: 1046.502261202394538,
  };
  noteFreq["C#6"] = {
    name: "C",
    octave: 6,
    accidental: "sharp",
    frequency: 1108.730523907488384,
  };
  noteFreq["D6"] = {
    name: "D",
    octave: 6,
    accidental: "natural",
    frequency: 1174.659071669630241,
  };
  noteFreq["D#6"] = {
    name: "D",
    octave: 6,
    accidental: "sharp",
    frequency: 1244.507934888323642,
  };
  noteFreq["E6"] = {
    name: "E",
    octave: 6,
    accidental: "natural",
    frequency: 1318.510227651479718,
  };
  noteFreq["F6"] = {
    name: "F",
    octave: 6,
    accidental: "natural",
    frequency: 1396.912925732015537,
  };
  noteFreq["F#6"] = {
    name: "F",
    octave: 6,
    accidental: "sharp",
    frequency: 1479.977690846537595,
  };
  noteFreq["G6"] = {
    name: "G",
    octave: 6,
    accidental: "natural",
    frequency: 1567.981743926997176,
  };
  noteFreq["G#6"] = {
    name: "G",
    octave: 6,
    accidental: "sharp",
    frequency: 1661.218790319780554,
  };
  noteFreq["A6"] = {
    name: "A",
    octave: 6,
    accidental: "natural",
    frequency: 1760.0,
  };
  noteFreq["A#6"] = {
    name: "A",
    octave: 6,
    accidental: "sharp",
    frequency: 1864.655046072359665,
  };
  noteFreq["B6"] = {
    name: "B",
    octave: 6,
    accidental: "natural",
    frequency: 1975.533205024496447,
  };

  noteFreq["C7"] = {
    name: "C",
    octave: 7,
    accidental: "natural",
    frequency: 2093.004522404789077,
  };

  return noteFreq;
};

const noteTable = createNoteTable();
export default noteTable;
