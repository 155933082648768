import { ISynthSettings } from "./synth";

export default {
  default: {
    envelope: {
      attack: 0,
      decay: 0,
      sustain: 1,
      release: 0,
    },
    lfo: {
      gain: 0,
      frequency: 1,
    },
    oscillators: [{ type: "sine", detune: 0, octave: 0, level: 0.3 }],
  },
  ringy: {
    envelope: {
      attack: 0.2,
      decay: 0.5,
      sustain: 0.8,
      release: 0.5,
    },
    lfo: {
      gain: 0,
      frequency: 1,
    },
    oscillators: [
      { type: "square", detune: 0, octave: 1, level: 0.3 },
      { type: "sawtooth", detune: 0, octave: -1, level: 0.1 },
      { type: "square", detune: 0, octave: 1, level: 0.1 },
    ],
  },
  epic: {
    envelope: { attack: 0.2, decay: 0.5, sustain: 0.8, release: 0.5 },
    lfo: { frequency: 4, gain: 105 },
    oscillators: [
      { type: "sawtooth", detune: 0, octave: -2, level: 0.3 },
      { type: "sawtooth", detune: -14, octave: 2, level: 0.1 },
      { type: "sawtooth", detune: 12, octave: -2, level: 0.5 },
    ],
  },
  accordion: {
    envelope: { attack: 0.2, decay: 0.33, sustain: 0.18, release: 0.24 },
    lfo: { frequency: 1, gain: 0 },
    oscillators: [
      { type: "square", level: 0.5, detune: 0, octave: -1 },
      { type: "square", level: 0.5, detune: -24, octave: -1 },
      { type: "square", level: 0.35, detune: 4, octave: 0 },
    ],
  },
  horns: {
    envelope: {
      attack: 0.06,
      decay: 1.0499999999999998,
      sustain: 0.58,
      release: 0.09,
    },
    lfo: { frequency: 3.09, gain: 50 },
    oscillators: [
      { type: "sawtooth", detune: 0, octave: 0, level: 0.59 },
      { type: "sawtooth", detune: 8, octave: -1, level: 0.13 },
      { type: "sawtooth", detune: -20, octave: 2, level: 0.02 },
    ],
  },
  clarinet: {
    envelope: {
      attack: 0.09,
      decay: 0.15000000000000002,
      sustain: 0.16,
      release: 0.09,
    },
    lfo: { frequency: 1, gain: 0 },
    oscillators: [
      { type: "square", detune: 0, octave: -1, level: 0.52 },
      { type: "sine", detune: -20, octave: -1, level: 0.38 },
      { type: "sine", detune: 15.999999999999986, octave: -1, level: 0.8 },
    ],
  },
  "touch-tone": {
    envelope: { attack: 0, decay: 0, sustain: 1, release: 0 },
    lfo: { frequency: 4, gain: 105 },
    oscillators: [
      { type: "sine", detune: 100, octave: 1, level: 0.8 },
      { type: "sine", detune: 10, octave: 2, level: 0.8 },
    ],
  },
  "buzz your girlfriend": {
    envelope: { attack: 0, decay: 0, sustain: 1, release: 0 },
    lfo: { frequency: 1, gain: 0 },
    oscillators: [{ type: "sawtooth", detune: 0, octave: -2, level: 1 }],
  },
  "dungeon lead": {
    envelope: {
      attack: 0.06,
      decay: 1.0499999999999998,
      sustain: 0.58,
      release: 1.23,
    },
    lfo: { frequency: 12.209999999999999, gain: 295 },
    oscillators: [
      { type: "square", detune: 0, octave: 1, level: 0.59 },
      { type: "square", detune: 8, octave: 2, level: 0.31 },
      { type: "sawtooth", detune: -20, octave: 2, level: 0.02 },
    ],
  },
  weird: {
    envelope: { attack: 0, decay: 0, sustain: 0.8, release: 0.33 },
    lfo: { frequency: 15.44, gain: 220 },
    oscillators: [
      { type: "sine", detune: 0, octave: 1, level: 0.68 },
      { type: "sine", level: 0.68, detune: -42.00000000000001, octave: -2 },
      { type: "square", level: 0.5, detune: -58, octave: 2 },
      { type: "sawtooth", level: 0.5, detune: 100, octave: 0 },
      { type: "sawtooth", level: 0.5, detune: -40, octave: -2 },
      { type: "square", level: 0.5, detune: 24, octave: -2 },
      { type: "sawtooth", level: 0.5, detune: -72, octave: -2 },
      { type: "square", level: 0.5, detune: 68, octave: 0 },
      { type: "square", level: 0.5, detune: 90, octave: 0 },
      { type: "square", level: 0.5, detune: 98, octave: 0 },
      { type: "square", level: 0.5, detune: 100, octave: 0 },
      { type: "square", level: 0.5, detune: 100, octave: 0 },
      { type: "square", level: 0.5, detune: 0, octave: 0 },
      { type: "square", level: 0.5, detune: 0, octave: 0 },
      { type: "square", level: 0.5, detune: 0, octave: 0 },
      { type: "square", level: 0.5, detune: 0, octave: 0 },
      { type: "square", level: 0.5, detune: -66, octave: 0 },
      { type: "square", level: 0.5, detune: 56, octave: 0 },
      { type: "square", level: 0.5, detune: 0, octave: 0 },
      { type: "square", level: 0.5, detune: -70, octave: 0 },
      { type: "sawtooth", level: 0.5, detune: 66, octave: -2 },
      { type: "square", level: 0.5, detune: 0, octave: 1 },
      { type: "square", level: 0.5, detune: -76, octave: -2 },
      { type: "sawtooth", level: 0.39, detune: 0, octave: -2 },
    ],
  },
} as Record<string, Pick<ISynthSettings, "envelope" | "lfo" | "oscillators">>;
