import React, { Component } from "react";

import Dial from "../dials/dial/dial";
import Keyboard from "../keyboard/keyboard";
import { ISynthSettings, OscillatorSettings, Synth } from "../../synth/synth";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Container from "../container/container";
import SteppedDial from "../dials/steppedDial/steppedDial";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  Switch,
  Tooltip,
} from "@mui/material";
import presets from "../../synth/presets";
import midiMap from "../../synth/midiMap";

const StyledSVG = styled("svg")`
  height: 8px;
  width: 12px;
  padding: 2px;
  & path {
    stroke: white;
    fill: transparent;
    stroke-width: 30px;
  }
`;

const Title = styled("div")`
  font-family: "Monoton", cursive;
  font-size: 3em;
  margin-left: 15px;
  color: whitesmoke;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ff0052),
    to(#8e2b88)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

// const styles = (theme: Theme): StyleRules => ({
//   paper: {
//     background: theme.palette.grey[900],
//   },
// });

const sine = (
  <path
    d="m0,0c100,0 100,200 200,200c100,0 110,-200 200,-200"
    transform="rotate(180) translate(-400 -210)"
  />
);

const saw = <path d="m0,200l200,-200l0,200l200,-200" />;

const square = (
  <path d="m0,200l100,0l0,-200c0,0 200,0 200,0c0,0 0,200 0,200c0,0 100,0 100,0" />
);

const triangle = (
  <path d="m0,200c0,0 140,-200 140,-200c0,0 110,200 110,200c0,0 150,-200 150,-200" />
);

const waveForms: { label: React.ReactElement<any>; value: OscillatorType }[] = [
  {
    label: (
      <StyledSVG viewBox="0 0 400 400" preserveAspectRatio="xMinYMin slice">
        {sine}
      </StyledSVG>
    ),
    value: "sine",
  },
  {
    label: (
      <StyledSVG viewBox="0 0 400 400" preserveAspectRatio="xMinYMin slice">
        {square}
      </StyledSVG>
    ),
    value: "square",
  },
  {
    label: (
      <StyledSVG viewBox="0 0 400 400" preserveAspectRatio="xMinYMin slice">
        {saw}
      </StyledSVG>
    ),
    value: "sawtooth",
  },
  {
    label: (
      <StyledSVG viewBox="0 0 400 400" preserveAspectRatio="xMinYMin slice">
        {triangle}
      </StyledSVG>
    ),
    value: "triangle",
  },
];

const octaves: { label: string | React.ReactElement<any>; value: any }[] = [
  {
    label: "-2",
    value: -2,
  },
  {
    label: "-1",
    value: -1,
  },
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
];

const Stack = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin: 8px;
`;

interface State {
  synthSettings: ISynthSettings;
  isPoweredOn: boolean;
  selectedPreset?: string;
}

const synth = new Synth({ ...presets.default, volume: 0.2 });

class SynthUI extends Component<{}, State> {
  playedNotes: Record<number, ReturnType<typeof synth.playNote>> = {};
  state: State = {
    isPoweredOn: false,
    synthSettings: { ...presets.default, volume: 0.2 },
    selectedPreset: "default",
  };

  componentDidMount() {
    if (navigator.requestMIDIAccess) {
      navigator
        .requestMIDIAccess()
        .then(this.onMIDISuccess, this.onMIDIFailure);
    }
  }

  render() {
    const { isPoweredOn, synthSettings, selectedPreset } = this.state;

    return (
      <Paper>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Stack>
              <Title>Synthoz</Title>
            </Stack>
          </Grid>
          <Grid item lg={2}></Grid>
          <Grid item lg={1}>
            <Container label="Power">
              <Switch
                checked={isPoweredOn}
                onChange={() => this.togglePower()}
              />
            </Container>
          </Grid>
          <Grid item lg={1}>
            <Container label="Volume">
              <Dial
                min={0}
                max={0.3}
                label="Level"
                value={(isPoweredOn && synthSettings.volume) || 0}
                valueChanged={(value: number) =>
                  this.updateSettings(
                    {
                      ...synthSettings,
                      volume: value,
                    },
                    false
                  )
                }
              />
            </Container>
          </Grid>

          <Grid item lg={3}>
            <Container label="LFO">
              <Dial
                min={0}
                max={500}
                label="Gain"
                value={(isPoweredOn && synthSettings.lfo.gain) || 0}
                valueChanged={(value: number) =>
                  this.updateSettings({
                    ...synthSettings,
                    lfo: {
                      frequency: synthSettings.lfo.frequency || 1,
                      gain: value,
                    },
                  })
                }
              />
              <Dial
                min={1}
                max={20}
                label="Rate"
                value={(isPoweredOn && synthSettings.lfo.frequency) || 1}
                valueChanged={(value: number) =>
                  this.updateSettings({
                    ...synthSettings,
                    lfo: {
                      frequency: value,
                      gain: synthSettings.lfo.gain || 0,
                    },
                  })
                }
              />
            </Container>
          </Grid>

          <Grid item lg={4}>
            <Container label="VCA EG">
              <Dial
                min={0}
                max={3}
                label="Attack"
                value={(isPoweredOn && synthSettings.envelope.attack) || 0}
                valueChanged={(value: number) =>
                  this.updateSettings({
                    ...synthSettings,
                    envelope: { ...synthSettings.envelope, attack: value },
                  })
                }
              />
              <Dial
                min={0}
                max={3}
                label="Decay"
                value={(isPoweredOn && synthSettings.envelope.decay) || 0}
                valueChanged={(value: number) =>
                  this.updateSettings({
                    ...synthSettings,
                    envelope: { ...synthSettings.envelope, decay: value },
                  })
                }
              />
              <Dial
                min={0}
                max={1}
                label="Sustain"
                value={(isPoweredOn && synthSettings.envelope.sustain) || 0}
                valueChanged={(value: number) =>
                  this.updateSettings({
                    ...synthSettings,
                    envelope: { ...synthSettings.envelope, sustain: value },
                  })
                }
              />
              <Dial
                min={0}
                max={3}
                label="Release"
                value={(isPoweredOn && synthSettings.envelope.release) || 0}
                valueChanged={(value: number) =>
                  this.updateSettings({
                    ...synthSettings,
                    envelope: { ...synthSettings.envelope, release: value },
                  })
                }
              />
            </Container>
          </Grid>
          <Grid item lg={2}></Grid>
          <Grid item lg={1}>
            <FormControl fullWidth>
              <InputLabel id="preset-selector">Preset</InputLabel>
              <Select
                disabled={!isPoweredOn}
                labelId="preset-selector"
                id="preset-selector-select"
                value={selectedPreset || ""}
                label="Age"
                sx={{ textTransform: "capitalize" }}
                onChange={this.handleSelectPreset}
              >
                {Object.keys(presets).map((preset) => (
                  <MenuItem
                    key={preset}
                    value={preset}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {preset}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4}></Grid>
          <Grid item lg={4}>
            <Container label="Voices">
              <Stack>
                {synthSettings.oscillators.map((oscillator, index) => (
                  <Container key={index}>
                    <Dial
                      min={0}
                      max={1}
                      label="Level"
                      value={(isPoweredOn && oscillator.level) || 0}
                      valueChanged={(value: number) => {
                        const newSettings = {
                          ...synthSettings,
                        };

                        newSettings.oscillators[index].level = value;
                        this.updateSettings(newSettings);
                      }}
                    />
                    <Dial
                      min={-100}
                      max={100}
                      label="Tune"
                      value={isPoweredOn ? oscillator.detune || 0 : -100}
                      valueChanged={(value: number) => {
                        const newSettings = {
                          ...synthSettings,
                        };

                        newSettings.oscillators[index].detune = value;
                        this.updateSettings(newSettings);
                      }}
                    />
                    <SteppedDial
                      label="Waveform"
                      steps={waveForms}
                      selectedIndex={waveForms.findIndex(
                        (wv) => wv.value === oscillator.type
                      )}
                      selectedStepChanged={(step) => {
                        const newSettings = {
                          ...synthSettings,
                        };

                        newSettings.oscillators[index].type = step.value;
                        this.updateSettings(newSettings);
                      }}
                    />
                    <SteppedDial
                      label="Octave"
                      steps={octaves}
                      selectedIndex={octaves.findIndex(
                        (o) => o.value === oscillator.octave
                      )}
                      selectedStepChanged={(step) => {
                        const newSettings = {
                          ...synthSettings,
                        };

                        newSettings.oscillators[index].octave = step.value;
                        this.updateSettings(newSettings);
                      }}
                    />
                    <Tooltip title="Remove Voice" placement="right">
                      <IconButton
                        onClick={() => this.removeOscillator(index)}
                        sx={{
                          alignSelf: "flex-start",
                          justifySelf: "flex-end",
                        }}
                      >
                        <CloseIcon sx={{ fontSize: 12 }} />
                      </IconButton>
                    </Tooltip>
                  </Container>
                ))}
                {/* {synthSettings.oscillators.length < 3 && ( */}
                <Tooltip title="Add Voice" placement="right">
                  <IconButton
                    onClick={() => this.addOscillator()}
                    sx={{
                      alignSelf: "flex-start",
                      justifySelf: "center",
                    }}
                  >
                    <AddIcon sx={{ fontSize: 12 }} />
                  </IconButton>
                </Tooltip>

                {/* )} */}
              </Stack>
            </Container>
          </Grid>
        </Grid>
        <Keyboard synth={synth} />
      </Paper>
    );
  }

  handleSelectPreset = (ev: SelectChangeEvent<string>) => {
    const selectedPreset = ev.target.value;
    const { synthSettings } = this.state;
    const newSettings = { ...synthSettings, ...presets[selectedPreset] };
    synth.updateSettings(newSettings);
    this.setState({ synthSettings: newSettings, selectedPreset });
  };

  updateSettings = (settings: ISynthSettings, invalidatePreset = true) => {
    synth.updateSettings(settings);
    if (invalidatePreset) {
      this.setState({ synthSettings: settings, selectedPreset: undefined });
    } else {
      this.setState({ synthSettings: settings });
    }
  };

  togglePower = async () => {
    const { isPoweredOn } = this.state;
    await synth.togglePower();
    this.setState({ isPoweredOn: !isPoweredOn });
  };

  removeOscillator = (index: number) => {
    const { synthSettings, isPoweredOn } = this.state;
    if (!isPoweredOn) {
      return;
    }
    const newSettings = {
      ...synthSettings,
    };
    newSettings.oscillators.splice(index, 1);
    synth.updateSettings(newSettings);

    this.setState({ synthSettings: newSettings, selectedPreset: undefined });
  };

  addOscillator = () => {
    const { synthSettings, isPoweredOn } = this.state;
    if (!isPoweredOn) {
      return;
    }
    const newSettings = {
      ...synthSettings,
      oscillators: [
        ...synthSettings.oscillators,
        {
          type: "square",
          level: 0.5,
          detune: 0,
          octave: 0,
        } as OscillatorSettings,
      ],
    };

    synth.updateSettings(newSettings);

    this.setState({ synthSettings: newSettings, selectedPreset: undefined });
  };

  onMIDISuccess = (midiAccess: WebMidi.MIDIAccess) => {
    for (const input of Array.from(midiAccess.inputs.values())) {
      input.onmidimessage = this.getMIDIMessage;
    }
  };

  onMIDIFailure = () => {};

  getMIDIMessage = (message: WebMidi.MIDIMessageEvent) => {
    const command = message.data[0];
    const note = message.data[1];
    const velocity = message.data.length > 2 ? message.data[2] : 0;

    switch (command) {
      case 144:
        if (velocity > 0) {
          this.noteOn(note, velocity);
        } else {
          this.noteOff(note);
        }
        break;

      case 128:
        this.noteOff(note);
        break;

      case 224:
        this.bend(128 * message.data[2] + message.data[1]);
        break;
    }
  };

  bend = (bendAmount: number) => {
    const valuePerSemitone = 8192 / 2;
    const adjustedBendAmount = bendAmount - 8192;
    synth.detuneNotes((adjustedBendAmount / valuePerSemitone) * 100);
  };
  noteOn = (noteCode: number, velocity = 1) => {
    const note = midiMap[noteCode];
    this.playedNotes[noteCode] = synth.playNote(note, velocity);
  };

  noteOff = (noteCode: number) => {
    this.playedNotes &&
      this.playedNotes[noteCode] &&
      this.playedNotes[noteCode]!.release();
  };
}

export default SynthUI;
